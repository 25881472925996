import React, { Component } from "react";
import {
  Table,
  Tag,
  Button,
  Input,
  InputNumber,
  Icon,
  Tooltip,
  Modal,
  Form,
  notification,
} from "antd";
import moment from "moment";

import AppContext from "../AppContext";

import StudentTag from "./StudentTag";
import AccessKey from "./AccessKey";

import axios from "axios";

class GroupTable extends Component {
  static contextType = AppContext;

  state = {};

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Find student"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const len = record[dataIndex].length;
      for (var i = 0; i < len; i++) {
        var curr = record[dataIndex][i];
        if (
          curr["name"].toLowerCase().includes(value.toLowerCase()) ||
          curr["zid"].toLowerCase().includes(value.toLowerCase())
        ) {
          return record;
        }
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: null });
  };

  handleSubmit = (e) => {
    const groupName = this.state.currentGroup.name;
    const { updateGroup } = this.props;
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        axios
          .post(`admin/group/${groupName}/update-parameters/`, {
            ...values,
          })
          .then((res) => {
            const group = res.data;
            this.setState({ visible: false, currentGroup: null }, () =>
              this.props.form.resetFields()
            );
            updateGroup(group);
            notification["success"]({
              message: `Successfully generated new access key for group ${groupName}`,
            });
          })
          .catch(() => {
            notification["error"]({
              message: `Failed to generate new access key for group ${groupName}`,
            });
          });
      }
    });
  };

  render() {
    const { groups, updateGroup, setFocus, focus } = this.props;
    const { searchText, currentGroup } = this.state;

    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: "Activity",
        key: "activity",
        render: (group) => {
          const lastActivity =
            group.name in this.props.lastActivity &&
            this.props.lastActivity[group.name] &&
            moment.unix(this.props.lastActivity[group.name]);

          const hasRecentActivity =
            lastActivity && moment().diff(lastActivity, "seconds") < 15;

          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexAlign: "center",
                justifyContent: "center",
              }}
            >
              <Tooltip
                title={`Last activity: ${
                  lastActivity ? lastActivity.format("HH:mm:ss") : "Never"
                }`}
              >
                <div
                  style={{
                    width: 20,
                    height: 20,
                    background: hasRecentActivity ? "#4CAF50" : "#F44336",
                    border: `2px solid ${
                      hasRecentActivity ? "#2E7D32" : "#C62828"
                    }`,
                    borderRadius: "50%",
                  }}
                />
              </Tooltip>
            </div>
          );
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (name, group) => (
          <div style={{ display: "flex" }}>
            <span style={{ cursor: "pointer" }} onClick={() => setFocus(name)}>
              {name}
            </span>
            <Tag style={{ marginLeft: 10 }}>{group.members.length}</Tag>
          </div>
        ),
      },
      {
        title: "Members",
        dataIndex: "members",
        key: "members",
        ...this.getColumnSearchProps("members"),
        render: (members, group) => (
          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            {[
              ...members.map((student) => (
                <StudentTag
                  key={student.zid}
                  student={student}
                  groupName={group.name}
                />
              )),
            ]}
          </div>
        ),
      },
      {
        title: "Access Key",
        dataIndex: "access_key",
        key: "accessKey",
        width: 250,
        render: (accessKey, group) => (
          <AccessKey
            access={accessKey}
            groupName={group.name}
            updateGroup={updateGroup}
          />
        ),
      },
      {
        title: "Target Power Min (Wh)",
        dataIndex: "target_power_min",
        key: "target_power_min",
        render: (target_power_min, group) => {
          const current_target_power_min = target_power_min
            ? target_power_min
            : 0;
          const current_tolerance = group.tolerance ? group.tolerance : 0;
          return (
            <>
              <span>{current_target_power_min}</span>
              <span style={{ color: "green" }}>
                &nbsp;(
                {current_target_power_min * (1 - current_tolerance / 100)})
              </span>
            </>
          );
        },
      },
      {
        title: "Target Power Max (Wh)",
        dataIndex: "target_power_max",
        key: "target_power_max",
        render: (target_power_max, group) => {
          const current_target_power_max = target_power_max
            ? target_power_max
            : 0;
          const current_tolerance = group.tolerance ? group.tolerance : 0;
          return (
            <>
              <span>{current_target_power_max}</span>
              <span style={{ color: "green" }}>
                &nbsp;(
                {current_target_power_max * (1 + current_tolerance / 100)})
              </span>
            </>
          );
        },
      },
      {
        title: "Target Power (Wh)",
        dataIndex: "target_power",
        key: "target_power",
        render: (target_power, group) => (
          <span>{target_power ? target_power : 0}</span>
        ),
      },
      {
        title: "Tolerance (%)",
        dataIndex: "tolerance",
        key: "tolerance",
        render: (tolerance, group) => <span>{tolerance ? tolerance : 0}</span>,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "edit",
        render: (tolerance, group) => (
          <Button
            onClick={() =>
              this.setState({ visible: true, currentGroup: group })
            }
          >
            Edit
          </Button>
        ),
      },
    ];

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return (
      <div>
        <Table
          size="small"
          bordered
          locale={{
            emptyText: searchText
              ? "No students match the filter provided"
              : "No groups have been created yet",
          }}
          columns={columns}
          dataSource={groups}
          rowKey="name"
          // pagination={false}
          className="group-table"
          onRow={(group) =>
            focus === group.name
              ? { style: { background: "#F5F5F5", fontSize: "8px !important" } }
              : {}
          }
        />
        <Modal
          title="Update Settings"
          visible={this.state.visible}
          onOk={this.hideModal}
          onCancel={() => this.setState({ visible: false })}
          footer={null}
        >
          <Form {...layout} onSubmit={this.handleSubmit}>
            <Form.Item label="Target Power Min">
              {getFieldDecorator("target_power_min", {
                initialValue:
                  currentGroup && currentGroup.target_power_min
                    ? currentGroup.target_power_min
                    : 0,
              })(<InputNumber min={0} placeholder="Target Power Min" />)}
            </Form.Item>
            <Form.Item label="Target Power Max">
              {getFieldDecorator("target_power_max", {
                initialValue: currentGroup ? currentGroup.target_power_max : 0,
              })(<InputNumber min={0} placeholder="Target Power Max" />)}
            </Form.Item>
            <Form.Item label="Target Power">
              {getFieldDecorator("target_power", {
                initialValue: currentGroup ? currentGroup.target_power : 0,
              })(<InputNumber placeholder="Target Power" />)}
            </Form.Item>
            <Form.Item label="Tolerance">
              {getFieldDecorator("tolerance", {
                initialValue:
                  currentGroup && currentGroup.tolerance
                    ? currentGroup.tolerance
                    : 0,
              })(<InputNumber min={0} max={100} placeholder="Tolerance" />)}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Form.create({ name: "horizontal_login" })(GroupTable);
