import React from "react";
import { Button } from "antd";

import vault from "../img/403.svg";

class NoGroup extends React.Component {
  render() {
    const { history } = this.props;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: "auto",
          margin: "40px 0"
        }}
      >
        <img src={vault} alt="Forbidden" />

        <div style={{ marginLeft: 30 }}>
          <h1
            style={{
              fontWeight: 600,
              fontSize: 48,
              color: "#434e59",
              marginBottom: 0
            }}
          >
            Access denied
          </h1>

          <div style={{ fontSize: 20, color: "rgba(0,0,0,.45)" }}>
            <p style={{ marginBottom: 5 }}>
              Sorry, you have not been assigned to a group yet.
            </p>
            <p>Please contact your course authority.</p>
          </div>

          <Button
            size="large"
            type="primary"
            onClick={() => history.replace("/")}
          >
            Try again
          </Button>
        </div>
      </div>
    );
  }
}

export default NoGroup;
