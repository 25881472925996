import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { Button, Layout } from "antd";
import axios from "axios";
import preval from "preval.macro";

import { authenticatedApplication } from "react-msal-jwt";
import { LandingPage } from "login-landing-page";

import AdminDashboard from "./components/AdminDashboard";
import UserDashboard from "./components/UserDashboard";
import GuestView from "./components/GuestView";

import Forbidden from "./error/Forbidden";
import ServerError from "./error/ServerError";
import NoGroup from "./error/NoGroup";

import AppContext from "./AppContext";

import "./App.css";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("access")}`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: props.isAdmin || sessionStorage.getItem("isAdmin") === "true",
    };
  }

  render() {
    const { getAzureToken, logout } = this.props;
    const { isAdmin } = this.state;

    return (
      <AppContext.Provider value={{ getAzureToken, isAdmin }}>
        <Layout style={{ height: "100%" }}>
          <Layout.Header style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
                alt="UNSW logo"
                style={{
                  maxHeight: "100%",
                  width: "auto",
                  padding: "10px 0",
                  marginRight: 25,
                }}
              />

              <h1 style={{ color: "#fff", margin: 0, fontSize: 32 }}>
                SOLA2051
              </h1>
            </div>

            <Button icon="poweroff" onClick={logout}>
              Log out
            </Button>
          </Layout.Header>

          <Layout.Content style={{ padding: 25 }}>
            <Layout style={{ padding: 25, background: "#fff" }}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) =>
                    isAdmin ? (
                      <AdminDashboard {...props} />
                    ) : (
                      <UserDashboard {...props} />
                    )
                  }
                />

                <Route exact path="/forbidden" component={Forbidden} />
                <Route exact path="/error" component={ServerError} />
                <Route exact path="/no-group" component={NoGroup} />
              </Switch>
            </Layout>
          </Layout.Content>
        </Layout>
      </AppContext.Provider>
    );
  }
}

export default () => (
  <Switch>
    {/* <Route exact path="/forbidden" component={Forbidden} />
    
    <Route exact path="/servererror" component={ServerError} />    */}
    <Route exact path="/live" component={GuestView} />
    <Route path="/" component={loginPage} />
  </Switch>
);

const loginPage = authenticatedApplication({
  landingPage: (
    <LandingPage
      title="SOLA2051"
      background="https://cdn.teaching.unsw.edu.au/161006_UNSW_016.jpg"
      logo={
        <a href="https://www.unsw.edu.au/">
          <img
            src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
            alt="UNSW Logo"
          />
        </a>
      }
      footerItems={[
        <a href="mailto:contact.pvce@unsw.edu.au">Contact us</a>,
        <a href="https://www.unsw.edu.au/privacy">Privacy Policy</a>,
        <a href="https://www.unsw.edu.au/copyright-disclaimer">
          Copyright &amp; Disclaimer
        </a>,
        <span style={{ color: "rgba(117, 117, 117, 0.5)" }}>
          {`Build date: ${preval`
            const moment = require("moment");
            module.exports = moment().format("DD/MM/YYYY");
          `}`}
        </span>,
      ]}
    />
  ),
  msalConfig: {
    auth: {
      clientId: process.env.REACT_APP_AZURE_APP_ID,
      authority: process.env.REACT_APP_AZURE_AUTHORITY,
      redirectUri: process.env.REACT_APP_FRONTEND_URL,
    },
  },
  onAuthSuccess: async (azureIdToken, azureAccessToken) => {
    const headers = {
      "Content-Type": "application/json; charset=utf8",
      Authorization: "Token " + azureIdToken,
    };
    const response = await axios.post(
      "auth/",
      { accessToken: azureAccessToken },
      { headers }
    );

    const data = response.data;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.access_token}`;
    sessionStorage.setItem("isAdmin", data.is_admin);

    return {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      extras: {
        isAdmin: data.is_admin,
      },
    };
  },
  onAuthError: (error) => {
    const { errorCode } = error;

    if (errorCode === "user_cancelled")
      return { type: "warning", message: "Login popup was closed." };
    else if (errorCode === "login_progress_error")
      return { type: "warning", message: "Login popup is already open." };
    else if (error.message === "Network Error")
      return {
        type: "error",
        message: (
          <>
            Failed to communicate with the server. If the issue persists, please{" "}
            <a href="mailto:contact.pvce@unsw.edu.au">contact support</a>.
          </>
        ),
      };
  },
  refreshAccess: async (refreshToken) => {
    const headers = {
      Authorization: "Bearer " + refreshToken,
    };
    const response = await axios.post("auth/refresh/", {}, { headers });

    const data = response.data;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${data.access_token}`;

    return data.access_token;
  },
  tokenCheckFrequency: 2,
})(App);
