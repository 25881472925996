import React, { Component } from "react";
import { Layout, Col, Spin, Icon, Row, Affix, Table, Switch } from "antd";
import axios from "axios";
import moment from "moment";
import ColorHash from "color-hash";
import memoize from "memoize-one";

import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const colorHash = new ColorHash();
class Guest extends Component {
  state = {
    loading: true,
    lastRefreshMessage: "Never",
    lastActivity: {},
    leaderboard: { power_in: [], power_out: [], temperature: [] },
    data: { power_in: {}, power_out: {}, temperature: {} },
    isFocused: true,
    leaderBoardType: "group",
  };

  componentDidMount = () => {
    this.refresh();
  };

  renderGraphs = memoize((focus, memoBust) => {
    const { data } = this.state;

    // memoBust used to bust the cache, because this number changes
    // every time a group's lastActivity changes
    return [
      { title: "Power In (W)", field: "power_in" },
      { title: "Power Out (W)", field: "power_out" },
      {
        title: (
          <span>
            Insolation (W/m<sup>2</sup>)
          </span>
        ),
        field: "temperature",
      },
    ].map((item) => (
      <div key={item.field} style={{ marginBottom: 24 }}>
        <h4>{item.title}</h4>
        <ResponsiveContainer width="95%" height={200}>
          <ScatterChart>
            <CartesianGrid />
            <XAxis
              dataKey="x"
              domain={["dataMin", "dataMax"]}
              name="Time"
              tickFormatter={(unixTime) =>
                moment.unix(unixTime).format("HH:mm:ss")
              }
              type="number"
            />
            <YAxis dataKey="y" name="Value" domain={["auto", "auto"]} />

            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              formatter={(value, name) => {
                if (name === "Time")
                  return moment.unix(value).format("HH:mm:ss");
                return value.toFixed(2);
              }}
            />
            <Legend />

            {(focus ? [focus] : Object.keys(data[item.field])).map((group) => (
              <Scatter
                key={group}
                data={
                  data[item.field][group]
                    ? data[item.field][group].sort((a, b) => a.x - b.x)
                    : []
                }
                line={{ stroke: colorHash.hex(group) }}
                lineJointType="monotoneX"
                lineType="joint"
                shape={<></>}
                name={group}
                fill={colorHash.hex(group)}
              />
            ))}
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    ));
  });

  updateLastRefreshMessage = () => {
    const { lastRefresh } = this.state;

    const secondsDifference = moment().diff(lastRefresh, "seconds");
    const lastRefreshMessage = lastRefresh
      ? `${secondsDifference < 1 ? "<1" : secondsDifference}s ago`
      : "Never";
    this.setState({ lastRefreshMessage });
  };

  getData = async () => {
    const response = await axios.get("group/");
    const {
      data,
      last_activity: lastActivity,
      leaderboard,
      should_collect: isCollecting,
    } = response.data;

    this.setState({
      data,
      storedDataPoints: true,
      lastActivity,
      leaderboard,
      isCollecting,
      loading: false,
      lastRefresh: moment(),
    });
  };

  refresh = () => {
    this.getData();

    this.refreshInterval = setInterval(async () => {
      this.getData();
    }, 3 * 1000); // Refresh every 3 seconds

    this.refreshMessageInterval = setInterval(() => {
      this.updateLastRefreshMessage();
    }, 1000);
  };

  setFocus = (group) => {
    const { focus } = this.state;
    this.setState({ focus: focus !== group && group });
  };

  render() {
    const {
      loading,
      lastRefreshMessage,
      leaderboard,
      focus,
      leaderBoardType,
    } = this.state;

    if (loading)
      return (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" indicator={<Icon type="loading" />} />
        </div>
      );

    const memoBust = Object.values(this.state.lastActivity).reduce(
      (total, value) => total + value,
      0
    );
    return (
      <div>
        {" "}
        <Layout style={{ height: "100%" }}>
          <Layout.Header style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <img
                src="https://cdn.teaching.unsw.edu.au/unswbranding/unsw_neg.png"
                alt="UNSW logo"
                style={{
                  maxHeight: "100%",
                  width: "auto",
                  padding: "10px 0",
                  marginRight: 25,
                }}
              />

              <h1 style={{ color: "#fff", margin: 0, fontSize: 32 }}>
                SOLA2051
              </h1>
            </div>
          </Layout.Header>
          <Layout.Content style={{ padding: 25, background: "#fff" }}>
            <div className="user">
              <div>
                <Row gutter={16}>
                  <Col xxl={6} xl={12} lg={24}>
                    <h3>
                      Leaderboard{" "}
                      <span>
                        <Switch
                          checkedChildren="Group"
                          unCheckedChildren="Individual"
                          defaultChecked
                          onChange={(val) =>
                            this.setState({
                              leaderBoardType: val ? "group" : "individual",
                            })
                          }
                        />
                      </span>
                    </h3>
                    {leaderboard[leaderBoardType] ? (
                      [
                        {
                          title: "Power In",
                          field: "power_in",
                          main: "P",
                          sub: "IN",
                          units: "Wh",
                        },
                        {
                          title: "Power Out",
                          field: "power_out",
                          main: "P",
                          sub: "OUT",
                          units: "Wh",
                        },
                        {
                          title: "Insolation",
                          field: "temperature",
                          main: "G",
                          sub: "",
                          units: "",
                        },
                      ].map((item) => (
                        <div key={item.field} style={{ marginBottom: 24 }}>
                          <h4>{item.title}</h4>
                          <Table
                            bordered
                            columns={[
                              {
                                title: "Ranking",
                                key: "ranking",

                                render: (value, group, index) => index + 1,
                              },
                              {
                                title:
                                  leaderBoardType === "group"
                                    ? "Group"
                                    : "Student",
                                key: "group",
                                dataIndex: "group",
                                render: (group) => (
                                  <span
                                    onClick={() => this.setFocus(group)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {group}
                                  </span>
                                ),
                              },
                              {
                                title: (
                                  <span>
                                    Σ{item.main}
                                    <sub>{item.sub}</sub>·Δt
                                    {item.units ? (
                                      <span> ({item.units})</span>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                ),
                                key: "value",
                                dataIndex: "value",
                                render: (value) => value.toFixed(6),
                              },
                            ]}
                            dataSource={
                              leaderboard[leaderBoardType][item.field]
                            }
                            rowKey="group"
                            pagination={false}
                            onRow={(item) =>
                              focus === item.group
                                ? { style: { background: "#F5F5F5" } }
                                : {}
                            }
                          />
                        </div>
                      ))
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <Spin
                          size="large"
                          indicator={<Icon type="loading" />}
                        />
                      </div>
                    )}
                  </Col>

                  <Col xxl={18} xl={12} lg={24}>
                    <Affix offsetTop={12}>
                      <h3>Graphs</h3>
                      {this.renderGraphs(focus, memoBust)}
                    </Affix>
                  </Col>
                </Row>
              </div>

              <div
                style={{
                  background: "rgba(0,0,0,0.45)",
                  width: "max-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "10px 20px",
                  position: "fixed",
                  bottom: 20,
                  right: 20,
                  borderRadius: 4,
                  color: "#fff",
                  minWidth: 200,
                }}
              >
                <strong style={{ marginRight: 6 }}>Data updated:</strong>
                {lastRefreshMessage}
              </div>
            </div>
          </Layout.Content>
        </Layout>
      </div>
    );
  }
}

export default Guest;
