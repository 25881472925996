import React, { Component } from "react";
import { withRouter } from "react-router";
import { Spin, Icon, Alert, Row, Col, Table, Radio, Switch } from "antd";
import axios from "axios";
import moment from "moment";
import ColorHash from "color-hash";
import memoize from "memoize-one";

import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import StudentTag from "./StudentTag";
import AccessKey from "./AccessKey";

const colorHash = new ColorHash();

class UserDashboard extends Component {
  state = {
    loading: true,
    lastRefreshMessage: "Never",
    lastActivity: {},
    leaderboard: { power_in: [], power_out: [], temperature: [] },
    data: { power_in: {}, power_out: {}, temperature: {} },
    isFocused: true,
    leaderBoardType: "group",
  };

  renderGraphs = memoize((isFocused, memoBust) => {
    const { group, data } = this.state;

    // memoBust used to bust the cache, because this number changes
    // every time a group's lastActivity changes
    return [
      { title: "Power In (W)", field: "power_in" },
      { title: "Power Out (W)", field: "power_out" },
      {
        title: (
          <span>
            Insolation (W/m<sup>2</sup>)
          </span>
        ),
        field: "temperature",
      },
    ].map((item) => (
      <Col span={24} key={item.field} style={{ marginBottom: 24 }}>
        <h4>{item.title}</h4>
        <ResponsiveContainer width="95%" height={300}>
          <ScatterChart>
            <CartesianGrid />
            <XAxis
              dataKey="x"
              domain={["dataMin", "dataMax"]}
              name="Time"
              tickFormatter={(unixTime) =>
                moment.unix(unixTime).format("HH:mm:ss")
              }
              type="number"
            />
            <YAxis dataKey="y" name="Value" domain={["auto", "auto"]} />

            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              formatter={(value, name) => {
                if (name === "Time")
                  return moment.unix(value).format("HH:mm:ss");
                return value.toFixed(2);
              }}
            />
            <Legend />

            {(isFocused ? [group.name] : Object.keys(data[item.field])).map(
              (group) => (
                <Scatter
                  key={group}
                  data={
                    data[item.field][group]
                      ? data[item.field][group].sort((a, b) => a.x - b.x)
                      : []
                  }
                  line={{ stroke: colorHash.hex(group) }}
                  lineJointType="monotoneX"
                  lineType="joint"
                  shape={<></>}
                  name={group}
                  fill={colorHash.hex(group)}
                />
              )
            )}
          </ScatterChart>
        </ResponsiveContainer>
      </Col>
    ));
  });

  componentDidMount() {
    const { history } = this.props;

    axios
      .get("student/")
      .then((res) => {
        const { group } = res.data;
        this.setState({
          group,
          loading: false,
        });
        this.refresh();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          history.replace("/no-group");
          return;
        }

        history.replace("/error");
      });
  }

  updateLastRefreshMessage = () => {
    const { lastRefresh } = this.state;

    const secondsDifference = moment().diff(lastRefresh, "seconds");
    const lastRefreshMessage = lastRefresh
      ? `${secondsDifference < 1 ? "<1" : secondsDifference}s ago`
      : "Never";
    this.setState({ lastRefreshMessage });
  };

  getData = async () => {
    const response = await axios.get("group/");
    const {
      data,
      last_activity: lastActivity,
      leaderboard,
      should_collect: isCollecting,
      target,
    } = response.data;

    this.setState({
      target,
      data,
      storedDataPoints: true,
      lastActivity,
      leaderboard,
      isCollecting,
      lastRefresh: moment(),
    });
  };

  refresh = () => {
    this.getData();

    this.refreshInterval = setInterval(async () => {
      this.getData();
    }, 3 * 1000); // Refresh every 3 seconds

    this.refreshMessageInterval = setInterval(() => {
      this.updateLastRefreshMessage();
    }, 1000);
  };

  render() {
    const {
      loading,
      group,
      lastRefreshMessage,
      leaderboard,
      isFocused,
      leaderBoardType,
      target,
    } = this.state;

    if (loading)
      return (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" indicator={<Icon type="loading" />} />
        </div>
      );

    const lastActivity =
      group.name in this.state.lastActivity &&
      this.state.lastActivity[group.name] &&
      moment.unix(this.state.lastActivity[group.name]);

    const hasRecentActivity =
      lastActivity && moment().diff(lastActivity, "seconds") < 10;

    const memoBust = Object.values(this.state.lastActivity).reduce(
      (total, value) => total + value,
      0
    );

    return (
      <div className="user">
        <h2>Group: {group.name}</h2>

        <Row gutter={32}>
          <Col lg={6} md={8} sm={24} style={{ marginBottom: 24 }}>
            <div style={{ marginBottom: 20 }}>
              <h3>Members</h3>
              <ul style={{ margin: 0 }}>
                {group.members.map((student, i) => (
                  <li key={i}>
                    <StudentTag student={student} groupName={group.name} />
                  </li>
                ))}
              </ul>
            </div>

            <div style={{ marginBottom: 20 }}>
              <h3>Access Key</h3>
              <AccessKey access={group.access_key} />
            </div>

            <div style={{ marginBottom: 20 }}>
              <Table
                bordered
                pagination={false}
                size="small"
                columns={[
                  { title: "Power Min", dataIndex: "target_power_min" },
                  { title: "Power Max", dataIndex: "target_power_max" },
                ]}
                dataSource={target ? target[group.name] : []}
                rowKey={(row) => row.group}
              />
            </div>

            <div>
              <h3>Connectivity</h3>

              <Alert
                style={{ maxWidth: 300 }}
                type={hasRecentActivity ? "success" : "error"}
                size="large"
                message={
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        background: hasRecentActivity ? "#4CAF50" : "#F44336",
                        border: `2px solid ${
                          hasRecentActivity ? "#2E7D32" : "#C62828"
                        }`,
                        borderRadius: "50%",
                        marginRight: 12,
                      }}
                    />
                    Last data received:{" "}
                    {lastActivity ? lastActivity.format("HH:mm:ss") : "Never"}
                  </div>
                }
              />
            </div>
          </Col>
          <Col lg={9} md={16} sm={24}>
            <h3>
              Leaderboard{" "}
              <span>
                <Switch
                  checkedChildren="Group"
                  unCheckedChildren="Individual"
                  defaultChecked
                  onChange={(val) =>
                    this.setState({
                      leaderBoardType: val ? "group" : "individual",
                    })
                  }
                />
              </span>
            </h3>

            <Row gutter={16}>
              {[
                {
                  title: "Power In",
                  field: "power_in",
                  main: "P",
                  sub: "IN",
                  units: "Wh",
                },
                {
                  title: "Power Out",
                  field: "power_out",
                  main: "P",
                  sub: "OUT",
                  units: "Wh",
                },
                {
                  title: "Insolation",
                  field: "temperature",
                  main: "G",
                  sub: "",
                  units: "",
                },
              ].map((item) => (
                <Col span={24} key={item.field} style={{ marginBottom: 24 }}>
                  <h4>{item.title}</h4>
                  <Table
                    bordered
                    columns={[
                      {
                        title: "Ranking",
                        key: "ranking",

                        render: (value, group, index) => index + 1,
                      },
                      {
                        title: "Group",
                        key: "group",
                        dataIndex: "group",
                        render: (group) => (
                          <span
                            onClick={() => this.setFocus(group)}
                            style={{ cursor: "pointer" }}
                          >
                            {group}
                          </span>
                        ),
                      },
                      {
                        title: (
                          <span>
                            Σ{item.main}
                            <sub>{item.sub}</sub>·Δt
                            {item.units ? <span> ({item.units})</span> : ""}
                          </span>
                        ),
                        key: "value",
                        dataIndex: "value",
                        render: (value) => value.toFixed(6),
                      },
                    ]}
                    dataSource={
                      leaderboard[leaderBoardType]
                        ? leaderboard[leaderBoardType][item.field]
                        : []
                    }
                    rowKey="group"
                    pagination={false}
                    onRow={(item) =>
                      item.group === group.name
                        ? { style: { background: "#F5F5F5" } }
                        : {}
                    }
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col
            lg={{ offset: "0", span: "9" }}
            md={{ offset: "8", span: "16" }}
            sm={{ offset: "0", span: "24" }}
          >
            <h3>Graphs</h3>

            <Radio.Group
              onChange={(e) => this.setState({ isFocused: e.target.value })}
              value={isFocused}
              buttonStyle="solid"
              style={{ marginBottom: 12 }}
            >
              <Radio.Button value={true}>Only my group</Radio.Button>
              <Radio.Button value={false}>All groups</Radio.Button>
            </Radio.Group>

            <Row gutter={8}>{this.renderGraphs(isFocused, memoBust)}</Row>
          </Col>
        </Row>

        <div
          style={{
            background: "rgba(0,0,0,0.45)",
            width: "max-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "10px 20px",
            position: "fixed",
            bottom: 20,
            right: 20,
            borderRadius: 4,
            color: "#fff",
            minWidth: 200,
          }}
        >
          <strong style={{ marginRight: 6 }}>Data updated:</strong>
          {lastRefreshMessage}
        </div>
      </div>
    );
  }
}

export default withRouter(UserDashboard);
