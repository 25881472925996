import React, { Component } from "react";
import { Input, Button, Tooltip, Modal, notification } from "antd";
import axios from "axios";

import AppContext from "../AppContext";

class AccessKey extends Component {
  static contextType = AppContext;

  state = { reveal: false, generating: false };

  generateNewKey = () => {
    const { groupName, updateGroup } = this.props;

    Modal.confirm({
      title: "Confirm new access key generation",
      content: `You are about to generate a new access key for group
      ${groupName}. This will prevent the group from recording additional
      information until their LabView is updated with the new key.`,
      okText: "Generate new key",
      onOk: () =>
        axios
          .post(`admin/group/${groupName}/generate-access-key/`)
          .then(res => {
            const group = res.data;
            updateGroup(group);
            notification["success"]({
              message: `Successfully generated new access key for group ${groupName}`
            });
          })
          .catch(() => {
            notification["error"]({
              message: `Failed to generate new access key for group ${groupName}`
            });
          })
    });
  };

  render() {
    const { access } = this.props;
    const { reveal } = this.state;
    const { isAdmin } = this.context;

    return (
      <div>
        <Input
          style={{
            width: 125,
            border: "1px solid rgba(217, 217, 217, 0.5)",
            background: "transparent",
            marginRight: 10
          }}
          value={access}
          type={reveal ? "text" : "password"}
        />

        <Tooltip title={reveal ? "Hide access key" : "Reveal access key"}>
          <Button
            style={{ marginRight: 5 }}
            shape="circle"
            icon={reveal ? "eye-invisible" : "eye"}
            onClick={() => this.setState({ reveal: !reveal })}
          />
        </Tooltip>

        {isAdmin && (
          <Tooltip title="Generate new access key">
            <Button shape="circle" icon="key" onClick={this.generateNewKey} />
          </Tooltip>
        )}
      </div>
    );
  }
}

export default AccessKey;
