import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Spin,
  Icon,
  Modal,
  Button,
  notification,
  Upload,
  Row,
  Col,
  Table,
  Switch,
  Alert,
  Dropdown,
  Menu,
  Affix,
} from "antd";
import axios from "axios";
import moment from "moment";
import ColorHash from "color-hash";
import memoize from "memoize-one";

import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import AppContext from "../AppContext";

import GroupTable from "./GroupTable";

const colorHash = new ColorHash();

class AdminDashboard extends Component {
  static contextType = AppContext;

  state = {
    loading: true,
    lastRefreshMessage: "Never",
    lastActivity: {},
    leaderboard: { power_in: [], power_out: [], temperature: [] },
    data: { power_in: {}, power_out: {}, temperature: {} },
    leaderBoardType: "group",
  };

  renderGraphs = memoize((focus, memoBust) => {
    const { data } = this.state;

    // memoBust used to bust the cache, because this number changes
    // every time a group's lastActivity changes
    return (
      <Row>
        {[
          { title: "Power In (W)", field: "power_in" },
          { title: "Power Out (W)", field: "power_out" },
          {
            title: (
              <span>
                Insolation (W/m<sup>2</sup>)
              </span>
            ),
            field: "temperature",
          },
        ].map((item) => (
          <Col span={8} key={item.field} style={{ marginBottom: 24 }}>
            <h4>{item.title}</h4>
            <ResponsiveContainer width="100%" height={180}>
              <ScatterChart>
                <CartesianGrid />
                <XAxis
                  dataKey="x"
                  domain={["dataMin", "dataMax"]}
                  name="Time"
                  tickFormatter={(unixTime) =>
                    moment.unix(unixTime).format("HH:mm:ss")
                  }
                  type="number"
                />
                <YAxis dataKey="y" name="Value" domain={["auto", "auto"]} />

                <Tooltip
                  cursor={{ strokeDasharray: "3 3" }}
                  formatter={(value, name) => {
                    if (name === "Time")
                      return moment.unix(value).format("HH:mm:ss");
                    return value.toFixed(2);
                  }}
                />
                <Legend />

                {(focus ? [focus] : Object.keys(data[item.field])).map(
                  (group) => (
                    <Scatter
                      key={group}
                      data={
                        data[item.field][group]
                          ? data[item.field][group].sort((a, b) => a.x - b.x)
                          : []
                      }
                      line={{ stroke: colorHash.hex(group) }}
                      lineJointType="monotoneX"
                      lineType="joint"
                      shape={<></>}
                      name={group}
                      fill={colorHash.hex(group)}
                    />
                  )
                )}
              </ScatterChart>
            </ResponsiveContainer>
          </Col>
        ))}
      </Row>
    );
  });

  updateGroup = (updatedGroup) => {
    let { groups } = this.state;

    const updatedGroups = [...groups];
    const updatedGroupIndex = updatedGroups.findIndex(
      (group) => group.name === updatedGroup.name
    );
    updatedGroups[updatedGroupIndex] = updatedGroup;

    this.setState({ groups: updatedGroups });
  };

  componentDidMount() {
    const { history } = this.props;

    axios
      .get("admin/")
      .then((response) => {
        const { groups, should_collect: isCollecting } = response.data;

        this.setState({
          groups,
          isCollecting,
          loading: false,
        });
        this.refresh();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          history.replace("/forbidden");
          return;
        }

        history.replace("/error");
      });
  }

  updateLastRefreshMessage = () => {
    const { lastRefresh } = this.state;

    const secondsDifference = moment().diff(lastRefresh, "seconds");
    const lastRefreshMessage = lastRefresh
      ? `${secondsDifference < 1 ? "<1" : secondsDifference}s ago`
      : "Never";
    this.setState({ lastRefreshMessage });
  };

  getData = async () => {
    const response = await axios.get("group/");

    const {
      data,
      last_activity: lastActivity,
      leaderboard,
      should_collect: isCollecting,
    } = response.data;

    this.setState({
      data,
      storedDataPoints: true,
      lastActivity,
      leaderboard,
      isCollecting,
      lastRefresh: moment(),
    });
  };

  refresh = () => {
    this.getData();

    this.refreshInterval = setInterval(async () => {
      this.getData();
    }, 3 * 1000); // Refresh every 3 seconds

    this.refreshMessageInterval = setInterval(() => {
      this.updateLastRefreshMessage();
    }, 1000);
  };

  wipeData = ({ key }) => {
    Modal.confirm({
      title: "Confirm data wipe",
      content: `Ensure that you have downloaded the data before wiping. This action irrevocably deletes all data stored.`,
      okText: "Proceed with wipe",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => {
        this.setState({ wiping: true });
        axios
          .post(`admin/wipe/${key === "all" ? "?all=1" : ""}`)
          .then(() => {
            notification["success"]({
              message: "Successfully wiped all data",
            });
            this.setState({
              groups: key === "all" ? [] : this.state.groups,
              lastActivity: {},
              leaderboard: { power_in: [], power_out: [], temperature: [] },
              data: { power_in: {}, power_out: {}, temperature: {} },
              wiping: false,
            });
          })
          .catch((error) => {
            notification["error"]({
              message: error.response.data,
              duration: 0,
            });
            this.setState({ wiping: false });
          });
      },
    });
  };

  handleUpload = async (file) => {
    const payload = new FormData();
    payload.append("file", file, file.name);

    this.setState({ uploading: true });

    try {
      const response = await axios.post(`admin/upload/`, payload, {
        config: { headers: { "Content-Type": "multipart/form-data" } },
      });
      const { groups } = response.data;
      notification["success"]({
        message: "Successfully imported groups/students",
      });
      this.setState({ groups, uploading: false });
    } catch (error) {
      console.log(error);
      notification["error"]({ message: error.response.data, duration: 0 });
      this.setState({ uploading: false });
    }
  };

  setFocus = (group) => {
    const { focus } = this.state;

    this.setState({ focus: focus !== group && group });
  };

  setDataCollection = async (checked) => {
    this.setState({ setting: true });

    try {
      await axios.post(`admin/collect/`, {
        should_collect: checked,
      });
      this.setState({ isCollecting: checked, setting: false });
    } catch (error) {
      notification["error"]({ message: error.response.data });
      this.setState({ setting: false });
    }
  };

  exportData = async () => {
    this.setState({ exporting: true });

    try {
      const response = await axios.post(`admin/export/`, {
        responseType: "blob",
      });
      this.setState({ exporting: false });

      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(new Blob([response.data]), {
        type: response.headers["content-type"],
      });

      const fileName = response.headers["content-disposition"].split(
        "filename="
      )[1];
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      notification["error"]({ message: error.response.data });
      this.setState({ exporting: false });
    }
  };

  onSaveParameters = (values) => {
    console.log(values);
  };

  render() {
    const {
      loading,
      groups,
      wiping,
      uploading,
      lastRefreshMessage,
      lastActivity,
      leaderboard,
      focus,
      isCollecting,
      setting,
      exporting,
      leaderBoardType,
    } = this.state;

    if (loading)
      return (
        <div style={{ textAlign: "center" }}>
          <Spin size="large" indicator={<Icon type="loading" />} />
        </div>
      );

    const memoBust = Object.values(lastActivity).reduce(
      (total, value) => total + value,
      0
    );

    return (
      <div>
        <h2>Administrative dashboard</h2>
        <Row>
          <Affix offsetTop={12}>
            <div className="admin-graphs">
              <h3>Graphs</h3>
              {this.renderGraphs(focus, memoBust)}
            </div>
          </Affix>
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Col span={18}>
            <h3>Tools</h3>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu onClick={this.wipeData}>
                    <Menu.Item key="all">All data</Menu.Item>
                    <Menu.Item key="data">Datapoints only</Menu.Item>
                  </Menu>
                }
              >
                <Button
                  type="danger"
                  icon="delete"
                  style={{ margin: 8, marginLeft: 0 }}
                  loading={wiping}
                >
                  Wipe data
                </Button>
              </Dropdown>

              <Upload
                accept="text/csv"
                beforeUpload={(file) => {
                  this.handleUpload(file);
                  return false;
                }}
                fileList={false}
              >
                <Button
                  icon="import"
                  loading={uploading}
                  style={{ margin: 8, marginLeft: 0 }}
                >
                  Import groups/students
                </Button>
              </Upload>

              <Button
                type="primary"
                icon="download"
                style={{ margin: 8, marginLeft: 0 }}
                onClick={this.exportData}
                loading={exporting}
              >
                Download data
              </Button>
            </div>
          </Col>

          <Col
            span={6}
            style={{ display: "flex", alignItems: "center", marginTop: 6 }}
          >
            Data Collection:
            <Switch
              style={{ marginLeft: 6, marginRight: 12 }}
              onChange={this.setDataCollection}
              checked={isCollecting}
              loading={setting}
            />
            <Alert
              style={{ maxWidth: 225 }}
              type={isCollecting ? "success" : "error"}
              message={`Data collection is ${
                isCollecting ? "enabled" : "disabled"
              }.`}
            />
          </Col>

          <Col span={24} style={{ marginTop: 15 }}>
            <h3>Groups</h3>
            <GroupTable
              setFocus={(focus) => this.setFocus(focus)}
              focus={focus}
              updateGroup={this.updateGroup}
              groups={groups}
              lastActivity={lastActivity}
              onSaveParameters={this.onSaveParameters}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 15 }}>
          {" "}
          <h3>
            Leaderboard{" "}
            <span>
              <Switch
                checkedChildren="Group"
                unCheckedChildren="Individual"
                defaultChecked
                onChange={(val) =>
                  this.setState({
                    leaderBoardType: val ? "group" : "individual",
                  })
                }
              />
            </span>
          </h3>
          {[
            {
              title: "Power In",
              field: "power_in",
              main: "P",
              sub: "IN",
              units: "Wh",
            },
            {
              title: "Power Out",
              field: "power_out",
              main: "P",
              sub: "OUT",
              units: "Wh",
            },
            {
              title: "Insolation",
              field: "temperature",
              main: "G",
              sub: "",
              units: null,
            },
          ].map((item) => (
            <Col span={8} key={item.field} style={{ padding: "10px" }}>
              <h4>{item.title}</h4>
              <Table
                bordered
                columns={[
                  {
                    title: "Ranking",
                    key: "ranking",

                    render: (value, group, index) => index + 1,
                  },
                  {
                    title: leaderBoardType === "group" ? "Group" : "Student",
                    key: "group",
                    dataIndex: "group",
                    render: (group) => (
                      <span
                        onClick={() => this.setFocus(group)}
                        style={{ cursor: "pointer" }}
                      >
                        {group}
                      </span>
                    ),
                  },
                  {
                    title: (
                      <span>
                        Σ{item.main}
                        <sub>{item.sub}</sub>·Δt
                        {item.units ? <span> ({item.units})</span> : ""}
                      </span>
                    ),
                    key: "value",
                    dataIndex: "value",
                    render: (value) => value.toFixed(6),
                  },
                ]}
                dataSource={
                  leaderboard[leaderBoardType]
                    ? leaderboard[leaderBoardType][item.field]
                    : []
                }
                rowKey="group"
                pagination={false}
                onRow={(item) =>
                  focus === item.group
                    ? { style: { background: "#F5F5F5" } }
                    : {}
                }
              />
            </Col>
          ))}
        </Row>
        <div
          style={{
            background: "rgba(0,0,0,0.45)",
            width: "max-content",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "10px 20px",
            position: "fixed",
            bottom: 20,
            right: 20,
            borderRadius: 4,
            color: "#fff",
            minWidth: 200,
          }}
        >
          <strong style={{ marginRight: 6 }}>Data updated:</strong>
          {lastRefreshMessage}
        </div>
      </div>
    );
  }
}

export default withRouter(AdminDashboard);
